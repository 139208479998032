import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class UtilityService {

    // преобразование заголовка в URL
    convertToUrl(text: string): string {
        const translitMap: { [key: string]: string } = {
            'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd', 'е': 'e', 'ё': 'e', 'ж': 'zh',
            'з': 'z', 'и': 'i', 'й': 'y', 'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n', 'о': 'o',
            'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u', 'ф': 'f', 'х': 'kh', 'ц': 'ts',
            'ч': 'ch', 'ш': 'sh', 'щ': 'shch', 'ы': 'y', 'э': 'e', 'ю': 'yu', 'я': 'ya',
            'і': 'i', 'ї': 'yi', 'є': 'ye', 'ґ': 'g', 'ь': '',
        };

        // Заменяем мягкий знак на пустую строку
        text = text.replace(/ь/g, '');

        // Заменяем пробел на минус
        text = text.replace(/\s+/g, '-');

        return text.toLowerCase().replace(/[а-яёіїєґ]/g, char => translitMap[char] || '-')
            .replace(/[^a-z0-9-]/g, '');
    }

    // конвертация изображения в base64
    convertToBase64(event: any): Promise<string | null> {
        return new Promise((resolve, reject) => {
            const file = event.target.files[0];
            const reader = new FileReader();

            reader.onloadend = () => {
                const base64 = reader.result as string;
                resolve(base64);
            };

            reader.onerror = () => {
                reject(reader.error);
            };

            if (file) {
                reader.readAsDataURL(file);
            } else {
                resolve(null);
            }
        });
    }

    async convertToBase64multiple(event: any): Promise<(string | null)[]> {
        const files = event.target.files;
        const promises: Promise<string | null>[] = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            promises.push(new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onloadend = () => {
                    const base64 = reader.result as string;
                    resolve(base64);
                };

                reader.onerror = () => {
                    reject(reader.error);
                };

                if (file) {
                    reader.readAsDataURL(file);
                } else {
                    resolve(null);
                }
            }));
        }

        return Promise.all(promises);
    }

    cutProductUrl(option: any, product: any) {
        const productUrl = product.url;
        // разделяем строки на массивы по дефису
        const optionUrlArray = option.url.split('-'); // "paket-100-sh"
        const productUrlArray = productUrl.split('-'); // "paket"
        // находим различия между массивами
        const difference = optionUrlArray.filter((x: any) => !productUrlArray.includes(x));
        // объединяем оставшиеся элементы в строку с дефисами
        return difference.join('-'); // "100 sh"
    }

    // добавление суффикса для отображения миниатюры изображения
    addSuffixToImageUrl(url: string, suffix: string): string {
        const extensionIndex = url.lastIndexOf('.');
        if (extensionIndex === -1) {
            return url; // если расширение не найдено, возвращаем оригинальный URL
        }
        // вставляем суффикс перед расширением
        return url.slice(0, extensionIndex) + '-' + suffix + url.slice(extensionIndex);
    }
}
