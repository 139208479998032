import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import { Location } from '@angular/common';
import {Editor, Toolbar} from "ngx-editor";
import {TOOLBAR_CONFIG} from "../../../../configs/toolbar.config";
import {ApiService} from "../../../../services/api.service";
import {MarketResolver} from "../../../../resolvers/market.resolver";
import {ErrorService} from "../../../common/notifications/errors/error.service";
import {ActivatedRoute, Router} from "@angular/router";
import {SuccessService} from "../../../common/notifications/success/success.service";
import {UtilityService} from "../../../../services/utility.service";
import {priceValidator} from "../../../../validators/price.validator";
import {ErrorTypeEnum} from "../../../../enums/error-type.enum";
import {EndpointTypeEnum} from "../../../../enums/endpoint-type.enum";
import {DEFAULT_MESSAGES} from "../../../../constants/default-messages";
import {environment} from "../../../../../environments/environment";
import {StorageService} from "../../../../services/storage.service";
import {AngularEditorConfig} from "@kolkov/angular-editor";
import {ToolbarEditor2ConfigService} from "../../../../configs/toolbar-editor-2.config";

@Component({
  selector: 'app-market-product-edit',
  templateUrl: './market-product-edit.component.html',
  styleUrl: './market-product-edit.component.scss'
})
export class MarketProductEditComponent implements OnInit, OnDestroy {
    backendUrl: string = environment.backendUrl;
    domain: string = environment.domain;
    market: any;
    product: any;
    productId: any = this.route.snapshot.paramMap.get('id');
    // форма
    form!: FormGroup;
    formWasSubmitted = false;
    // для выпадающих списков
    categoriesForDropdown: any;
    attributesForDropdown: any;
    optionsForDropdown: any;
    unitsForDropdown: any;
    activeOptions: any;
    // выбранные объекты
    addedAttributes: any[] = [];
    addedOptions: any[] = [];
    selectedCategories: string[] = [];
    // изображения
    gallery: { image: string | null; is_main: boolean; video: string | null }[] = [];
    imageOpenGraph: string;
    base64openGraph: any;
    mainGalleryVideo: any;
    mainGalleryImage: any;
    secondaryGalleryImages: any[] = [];
    selectedImageId: any;
    // для подтверждения в модалке
    showConfirmation = false;
    // wysiwyg
    editorDescriptionRu: Editor;
    editorDescriptionUa: Editor;
    toolbar: Toolbar = TOOLBAR_CONFIG;
    editorConfig: AngularEditorConfig;
    // для валидации полей при добавлении attribute value
    fieldNameRuInAttributes: any[] = [];
    fieldNameUaInAttributes: any[] = [];
    fieldAddedAttributesInAttributes: any[] = [];
    // для валидации полей при добавлении options
    fieldNameRuInOptions: any[] = [];
    fieldNameUaInOptions: any[] = [];
    fieldPriceInOptions: any[] = [];
    fieldUrlInOptions: any[] = [];
    fieldAddedOptionsInOptions: any[] = [];
    // для блока "С этими товарами смотрели"
    optionsControlForViewedWith = new FormControl('');
    optionsForViewedWith: any[] = [];
    selectedOptionsViewedWith: any[] = [];
    filteredOptionsViewedWith: any[] = [];
    googleMerchant: any;
    selectedCategoriesForMerchant: string[] = [];

    @ViewChild('input') input: ElementRef<HTMLInputElement>;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private apiService: ApiService,
        private formBuilder: FormBuilder,
        private marketResolver: MarketResolver,
        private utilityService: UtilityService,
        private errorService: ErrorService,
        private successService: SuccessService,
        public storageService: StorageService,
        private location: Location,
        private toolbarEditor2ConfigService: ToolbarEditor2ConfigService,
    ) {
        // получаем market
        this.marketResolver.market$.subscribe(market => {
            this.market = market;
        });

        this.editorConfig = this.toolbarEditor2ConfigService.getConfig();

        this.getGoogleMerchant()
        this.getCategoriesForDropdown();
        this.getAttributesForDropdown();
        this.getOptionsForDropdown();
        this.getUnitsForDropdown();
        this.getProductOptions();
        this.getAllActiveOptionsByProduct(this.productId);
        this.initForm();
        this.filteredOptionsViewedWith = this.optionsForViewedWith.slice() || [];
    }

    // инициализация формы
    initForm(): void {
        // инициализируем поля из формы и валидируем
        this.form = this.formBuilder.group({
            // ru
            name_ru:             ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
            description_ru:      [''],
            meta_title_ru:       ['', [Validators.minLength(2), Validators.maxLength(255)]],
            meta_description_ru: ['', [Validators.minLength(2), Validators.maxLength(255)]],
            // ua
            name_ua:             ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255)]],
            description_ua:      [''],
            meta_title_ua:       ['', [Validators.minLength(2), Validators.maxLength(255)]],
            meta_description_ua: ['', [Validators.minLength(2), Validators.maxLength(255)]],
            // общие поля
            url:                 ['', [Validators.required, Validators.minLength(2), Validators.maxLength(255), Validators.pattern(/^[a-z0-9-]+$/i)]],
            article:             ['', [Validators.required, Validators.minLength(2), Validators.maxLength(40)]],
            upc:                 ['', [Validators.minLength(2), Validators.maxLength(40)]],
            ean:                 ['', [Validators.minLength(2), Validators.maxLength(40)]],
            jan:                 ['', [Validators.minLength(2), Validators.maxLength(40)]],
            mpn:                 ['', [Validators.minLength(2), Validators.maxLength(40)]],
            purchase_price:      ['', [priceValidator, Validators.minLength(2), Validators.maxLength(10)]],
            availability:        ['1', Validators.required],
            amount:              ['', [Validators.pattern(/^[0-9]+$/)]],
            status:              ['1', Validators.required],
            is_new:              ['1'],
            is_product_of_week:  ['1'],
            opengraph_header_ru: [''],
            opengraph_header_ua: [''],
            opengraph_description_ru: [''],
            opengraph_description_ua: [''],
            opengraph_image:     ['', [Validators.pattern(/\.(jpg|png|svg)$/i)]],
            // связи
            mainGalleryVideo:    [''],
            mainGalleryImage:    ['', [Validators.pattern(/\.(jpg|png|svg)$/i)]],
            secondaryGalleryImages: ['', [Validators.pattern(/\.(jpg|png|svg)$/i)]],
            price:               ['', [Validators.required, priceValidator, Validators.minLength(2), Validators.maxLength(14)]],
            price_new:           ['', [priceValidator, Validators.minLength(2), Validators.maxLength(10)]],
            main_category:       ['', Validators.required],
            unit:                [''],
            categories:          [''],
            added_attributes:    [''],
            // отправитель
            market:              [this.market._id]
        });
    }

    ngOnInit() {
        if (this.productId !== null) {
            this.getProduct(this.productId);
        }

        this.editorDescriptionRu = new Editor();
        this.editorDescriptionUa = new Editor();
    }

    ngOnDestroy(): void {
        this.storageService.removeProductPage();

        this.editorDescriptionRu.destroy();
        this.editorDescriptionUa.destroy();

        this.successService.clear(ErrorTypeEnum.Global);
        this.successService.clear(ErrorTypeEnum.Local);
        this.errorService.clearErrors(ErrorTypeEnum.Global);
        this.errorService.clearErrors(ErrorTypeEnum.Local);
    }

    // -- логика для: attributes --
    // добавление полей для attributes
    addAttribute() {
        this.addedAttributes.push({ attribute: '', name_ru: '', name_ua: '' });

        const newIndex = this.addedAttributes.length - 1;
        this.fieldAddedAttributesInAttributes[newIndex] = { isValid: false };
        this.fieldNameRuInAttributes[newIndex]          = { isValid: false };
        this.fieldNameUaInAttributes[newIndex]          = { isValid: false };
    }

    // -- логика для: attributes --
    // удаление полей для attributes
    removeAttribute(index: number) {
        this.addedAttributes.splice(index, 1);

        this.fieldAddedAttributesInAttributes.splice(index, 1);
        this.fieldNameRuInAttributes.splice(index, 1);
        this.fieldNameUaInAttributes.splice(index, 1);
    }

    // -- логика для: attributes --
    // обновление значений в полях для attributes
    onUpdateAttribute(index: any, field: string, event: any): void {
        switch (field) {
            case 'attribute':
                // записываем значение поля attribute в addedAttributes
                this.addedAttributes[index].attribute = event.value;
                // записываем значение поля isValid в fieldAddedAttributesInAttributes для дальнейшей валидации
                this.fieldAddedAttributesInAttributes[index].isValid = !(event.value === '');
                break;
            case 'name_ru':
                // записываем значение поля name_ru в addedAttributes
                this.addedAttributes[index].name_ru = event.target.value;
                // записываем значение поля isValid в fieldNameRuInAttributes для дальнейшей валидации
                this.fieldNameRuInAttributes[index].isValid = !(event.target.value === '');
                break;
            case 'name_ua':
                // записываем значение поля name_ua в addedAttributes
                this.addedAttributes[index].name_ua = event.target.value;
                // записываем значение поля isValid в fieldNameUaInAttributes для дальнейшей валидации
                this.fieldNameUaInAttributes[index].isValid = !(event.target.value === '');
                break;
            default:
                break;
        }
    }

    // -- логика для: options --
    // добавление полей для options
    addOption() {
        this.addedOptions.push({
            main_option: '',
            name_ru: '',
            name_ua: '',
            price: '',
            price_new: '',
            url: '',
            is_color: false,
            color: '#000',
            price_buy: '',
            article: '',
            is_in_stock: true,
            is_active: true,
            main_image: '',
            secondary_images: []
        });

        const newIndex = this.addedOptions.length - 1;
        this.fieldAddedOptionsInOptions[newIndex] = { isValid: false };
        this.fieldNameRuInOptions[newIndex]       = { isValid: false };
        this.fieldNameUaInOptions[newIndex]       = { isValid: false };
        this.fieldPriceInOptions[newIndex]        = { isValid: false };
        this.fieldUrlInOptions[newIndex]          = { isValid: false };
    }

    // -- логика для: options --
    // удаление полей для options
    removeOption(index: number) {
        this.addedOptions.splice(index, 1);

        this.fieldAddedOptionsInOptions.splice(index, 1);
        this.fieldNameRuInOptions.splice(index, 1);
        this.fieldNameUaInOptions.splice(index, 1);
        this.fieldPriceInOptions.splice(index, 1);
        this.fieldUrlInOptions.splice(index, 1);
    }

    // -- логика для: С этими товарами смотрят --
    filterOptionsViewedWith(): void {
        const filterValue = this.input.nativeElement.value.toLowerCase();
        this.filteredOptionsViewedWith = this.optionsForViewedWith.filter(o => o.value_ua && o.value_ua.toLowerCase().includes(filterValue));
    }

    // -- логика для: С этими товарами смотрят --
    onUpdateOptionsViewedWith(event: any) {
        const selectedOption = event.option.value;
        if (!this.selectedOptionsViewedWith.includes(selectedOption)) {
            this.selectedOptionsViewedWith.push(selectedOption);
        }
        this.optionsControlForViewedWith.setValue('');
    }

    // -- логика для: С этими товарами смотрят --
    removeOptionViewedWith(index: number) {
        this.selectedOptionsViewedWith.splice(index, 1);
    }

    // -- логика для: options --
    // обновление значений в полях для options
    onUpdateOption(index: number, field: string, event: any, type: "boolean" | ""  = ""): void {
        switch (field) {
            case 'main_option':
                // записываем значение поля в addedOptions
                this.addedOptions[index].main_option = event.value;
                // записываем значение поля isValid в fieldAddedOptionsInOptions для дальнейшей валидации
                this.fieldAddedOptionsInOptions[index].isValid = !(event.value === '');
                return;
            case 'name_ru':
                // записываем значение поля в addedOptions
                this.addedOptions[index].name_ru = event.target.value;
                // записываем значение поля isValid в fieldNameRuInOptions для дальнейшей валидации
                this.fieldNameRuInOptions[index].isValid = !(event.target.value === '');
                return;
            case 'name_ua':
                // записываем значение поля в addedOptions
                this.addedOptions[index].name_ua = event.target.value;
                // записываем значение поля isValid в fieldNameUaInOptions для дальнейшей валидации
                this.fieldNameUaInOptions[index].isValid = !(event.target.value === '');
                return;
            case 'price':
                // записываем значение поля в addedOptions
                this.addedOptions[index].price = event.target.value;
                // записываем значение поля isValid в fieldNameUaInOptions для дальнейшей валидации
                this.fieldPriceInOptions[index].isValid = !(event.target.value === '');
                return;
            case 'price_new':
                // записываем значение поля в addedOptions
                this.addedOptions[index].price_new = event.target.value;
                return;
            case 'url':
                // записываем значение поля в addedOptions
                this.addedOptions[index].url = event.target.value;
                // записываем значение поля isValid в fieldNameUaInOptions для дальнейшей валидации
                this.fieldUrlInOptions[index].isValid = !(event.target.value === '');
                return;
            case 'is_color':
                // записываем значение поля в addedOptions
                this.addedOptions[index].is_color = event.value || false;
                return;
            case 'color':
                // записываем значение поля в addedOptions
                this.addedOptions[index].color = event.target.value;
                return;
            // case 'main_image':
            //     // записываем значение поля в addedOptions
            //     this.uploadPhoto(event, (photos: any) => {
            //         this.addedOptions[index].main_image = photos;
            //     })
            //     return;
            // case 'secondary_images':
            //     // записываем значение поля в addedOptions
            //     this.uploadPhoto(event, (photos: any) => {
            //         this.addedOptions[index].secondary_images.push(photos)
            //     })
            //     return;
            default:
                break;
        }

        let value = ""
        switch (type) {
            case 'boolean':
                value = event.value || false;
                break;
            default:
                value = event.target.value
                break;
        }
        this.addedOptions[index][field] = value;
    }

    // -- логика для: categories --
    // чекбокс для выбора категорий
    updateSelectedCategories(categoryId: string): void {
        if (this.isSelectedCategories(categoryId)) {
            this.selectedCategories = this.selectedCategories.filter(id => id !== categoryId);
        } else {
            this.selectedCategories.push(categoryId);
        }
    }

    // -- логика для: categories --
    // чекбокс для отображения выбранных категорий
    isSelectedCategories(categoryId: string): boolean {
        return this.selectedCategories.includes(categoryId);
    }

    // получение данных для выпадающего списка
    getCategoriesForDropdown(): void {
        this.apiService
            .get(EndpointTypeEnum.DropdownLists, '/categories', true)
            .subscribe({
                next: (response: any) => {
                    this.categoriesForDropdown = response.data;
                },
                error: (err) => {
                    this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
                },
            });
    }

    // получение данных для выпадающего списка
    getAttributesForDropdown(): void {
        this.apiService
            .get(EndpointTypeEnum.DropdownLists, '/attributes', true)
            .subscribe({
                next: (response: any) => {
                    this.attributesForDropdown = response.data;
                },
                error: (err) => {
                    this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
                },
            });
    }

    // получение данных для выпадающего списка
    getOptionsForDropdown(): void {
        this.apiService
            .get(EndpointTypeEnum.DropdownLists, '/options', true)
            .subscribe({
                next: (response: any) => {
                    this.optionsForDropdown = response.data;
                },
                error: (err) => {
                    this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
                },
            });
    }

    // получение данных для выпадающего списка
    getProductOptions(): void {
        this.apiService
            .get(EndpointTypeEnum.DropdownLists, '/product-options', true)
            .subscribe({
                next: (response: any) => {
                    this.optionsForViewedWith = response.data;
                },
                error: (err) => {
                    this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
                },
            });
    }

    // получение данных для выпадающего списка
    getUnitsForDropdown(): void {
        this.apiService
            .get(EndpointTypeEnum.DropdownLists, '/units', true)
            .subscribe({
                next: (response: any) => {
                    this.unitsForDropdown = response.data;
                },
                error: (err) => {
                    this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
                },
            });
    }

    getAllActiveOptionsByProduct(productId: string): void {
        this.apiService
            .get(EndpointTypeEnum.Market, '/products/active-options', true, productId)
            .subscribe({
                next: (response: any) => {
                    this.activeOptions = response.data;
                },
                error: (err) => {
                    this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
                },
            });
    }

    onUpdateVideo(event: any) {
        // сначала очищаем предыдущее видео (если оно есть)
        const mainVideoIndex = this.gallery.findIndex(item => item.video);
        if (mainVideoIndex !== -1) {
            this.gallery.splice(mainVideoIndex, 1);
            // this.mainGalleryVideo = null;
        }

        if (event.target.value !== '') {
            this.gallery.push({image: null, is_main: false, video: event.target.value});
        }

        console.log(this.gallery);
    }

    uploadPhoto(event: any,onSave:(photos: any)=> void): void {
        const filesList: FileList = event.target.files;
        const files = Array.from(filesList);
        const formData = new FormData();

        files.forEach((file: any) => {
            formData.append('image', file);
            this.apiService
                .postMultipartFormData(EndpointTypeEnum.Requests, '/upload-files', true, formData)
                .subscribe({
                    next: (response: any) => {
                        onSave(response.data);
                        this.errorService.clearErrors(ErrorTypeEnum.Local);
                    },
                    error: (err: any) => {
                        this.errorService.addError(ErrorTypeEnum.Local, [err.error.message]);
                    },
                });
        });
    }

    uploadImage(event: any, type: 'mainGalleryImage' | 'secondaryGalleryImages' | 'openGraphImage'): void {
        // const files = event.target.files;
        const filesList: FileList = event.target.files;
        const files = Array.from(filesList);
        const formData = new FormData();

        if (type === 'secondaryGalleryImages') {
            // this.gallery = this.gallery.filter(item => item.is_main);
            files.forEach((file: any) => {
                formData.append('image', file);
                this.apiService
                    .postMultipartFormData(EndpointTypeEnum.Requests, '/upload-files', true, formData)
                    .subscribe({
                        next: (response: any) => {
                            this.gallery.push({image: response.data, is_main: false, video: null});
                            this.errorService.clearErrors(ErrorTypeEnum.Local);
                        },
                        error: (err: any) => {
                            this.errorService.addError(ErrorTypeEnum.Local, [err.error.message]);
                        },
                    });
            });
        } else if (type === 'mainGalleryImage') {
            // сначала очищаем предыдущее изображение (если оно есть)
            // у которого is_main: true
            const mainImageIndex = this.gallery.findIndex(item => item.is_main);
            if (mainImageIndex !== -1) {
                this.gallery.splice(mainImageIndex, 1);
            }
            // добавляем новое изображения
            files.forEach((file: any) => {
                formData.append('image', file);
                this.apiService
                    .postMultipartFormData(EndpointTypeEnum.Requests, '/upload-files', true, formData)
                    .subscribe({
                        next: (response: any) => {
                            this.gallery.push({image: response.data, is_main: true, video: null});
                            this.errorService.clearErrors(ErrorTypeEnum.Local);
                        },
                        error: (err: any) => {
                            this.errorService.addError(ErrorTypeEnum.Local, [err.error.message]);
                        },
                    });
            });
        } else if (type === 'openGraphImage') {
            this.base64openGraph = files[0];
            formData.append('image', files[0]);
            this.apiService
                .postMultipartFormData(EndpointTypeEnum.Requests, '/upload-files', true, formData)
                .subscribe({
                    next: (response: any) => {
                        this.imageOpenGraph = response.data;
                        this.errorService.clearErrors(ErrorTypeEnum.Local);
                    },
                    error: (err: any) => {
                        this.errorService.addError(ErrorTypeEnum.Local, [err.error.message]);
                    },
                });
        }
    }

    // получение данных для формы
    getProduct(productId: string) {
        this.apiService
            .get(EndpointTypeEnum.Market, '/products', true, productId)
            .subscribe({
            next: (response: any) => {
                this.product = response.data;
                // получаем price из option "default"
                const defaultOptionType = this.product.options.find((option: any) => option.type === 'default');
                const priceFromOption = defaultOptionType ? defaultOptionType.price : null;
                const priceNewFromOption = defaultOptionType ? defaultOptionType.price_new : null;
                // логика работы с галлереей
                this.gallery = [];
                this.mainGalleryImage = null;
                this.secondaryGalleryImages = [];
                this.mainGalleryVideo = null;
                for (const image of this.product.gallery) {
                    // заполнение переменной this.gallery всеми объектами галлереи
                    this.gallery.push({image: image.image, is_main: image.is_main, video: image.video});

                    if (image.is_main && image.image) {
                        // задаем в mainGalleryImage главное изображение для вывода
                        this.mainGalleryImage = image;
                    }

                    if (!image.is_main && image.image) {
                        // задаем в secondaryGalleryImages второстепенные изображения для вывода
                        this.secondaryGalleryImages.push(image);
                    }

                    if (!image.is_main && image.video) {
                        // задаем в mainGalleryVideo видео для вывода
                        this.mainGalleryVideo = image;
                    }
                }
                // добавляем заполненные чекбоксы для categories
                this.selectedCategories = this.selectedCategories.concat(this.product.categories.map((categories: any) => categories._id));
                // добавляем заполненные attribute_values и поля валидации для них
                for (const attributeValue of this.product.attribute_values) {
                    this.addedAttributes.push({
                        attribute: attributeValue.attribute,
                        name_ru: attributeValue.name_ru,
                        name_ua: attributeValue.name_ua,
                    });
                    const newIndex = this.addedAttributes.length - 1;
                    this.fieldAddedAttributesInAttributes[newIndex] = { isValid: true };
                    this.fieldNameRuInAttributes[newIndex]          = { isValid: true };
                    this.fieldNameUaInAttributes[newIndex]          = { isValid: true };
                }
                // добавляем заполненные options и поля валидации для них
                for (const option of this.product.options) {
                    if (option.type === 'custom') {
                        this.addedOptions.push({
                            _id: option._id,
                            main_option: option.main_option._id,
                            name_ru: option.value_ru,
                            name_ua: option.value_ua,
                            price: option.price,
                            price_new: option.price_new,
                            url: this.utilityService.cutProductUrl(option, this.product),
                            is_color: option.is_color,
                            color: option.color,
                            price_buy: option.price_buy,
                            article:option.article,
                            is_in_stock: option.is_in_stock,
                            is_active:option.is_active,
                        });
                    }
                    const newIndex = this.addedOptions.length - 1;
                    this.fieldAddedOptionsInOptions[newIndex]    = { isValid: true };
                    this.fieldNameRuInOptions[newIndex]          = { isValid: true };
                    this.fieldNameUaInOptions[newIndex]          = { isValid: true };
                    this.fieldPriceInOptions[newIndex]           = { isValid: true };
                    this.fieldUrlInOptions[newIndex]             = { isValid: true };
                }
                // добавляем заполненные данные для options_viewed_with
                this.selectedOptionsViewedWith = [];
                for (const option of this.product.options_viewed_with) {
                    this.selectedOptionsViewedWith.push({
                        ...option,
                        // value_ua: option.product?.name_ua + ' ' + option.value_ua,
                        value_ua: option.value_ua !== null ? `${option.product?.name_ua} ${option.value_ua}` : option.product?.name_ua,
                        value_ru: option.value_ru !== null ? `${option.product?.name_ru} ${option.value_ru}` : option.product?.name_ru,
                    });
                }

                let price_new = '';
                let purchase_price = this.product.purchase_price;
                let price = priceFromOption;

                const convertToFormat = (value: string) => {
                    if (value.indexOf('.') > 0) {
                        const signAfterDot = value.substring(value.indexOf('.') + 1);
                        (signAfterDot.length === 1) && (value += '0');
                    }
                    return value;
                }

                if (priceNewFromOption)
                    price_new = convertToFormat(priceNewFromOption.indexOf('.') < 0 ? `${priceNewFromOption}.00` : priceNewFromOption)

                if (purchase_price)
                    purchase_price = convertToFormat(purchase_price.indexOf('.') < 0 ? `${purchase_price}.00` : purchase_price)

                if (priceFromOption)
                    price = convertToFormat(priceFromOption.indexOf('.') > 0 ? priceFromOption : `${priceFromOption}.00`)

                // установка значения полей
                this.form.patchValue({
                    name_ru: this.product.name_ru,
                    name_ua: this.product.name_ua,
                    description_ru: this.product.description_ru,
                    description_ua: this.product.description_ua,
                    meta_title_ru: this.product.meta_title_ru,
                    meta_title_ua: this.product.meta_title_ua,
                    meta_description_ru: this.product.meta_description_ru,
                    meta_description_ua: this.product.meta_description_ua,
                    url: this.product.url,
                    article: this.product.article,
                    upc: this.product.upc,
                    ean: this.product.ean,
                    jan: this.product.jan,
                    mpn: this.product.mpn,
                    availability: this.product.availability ? this.product.availability = 1 : this.product.availability = 0,
                    amount: this.product.amount,
                    status: this.product.status ? this.product.status = 1 : this.product.status = 0,
                    is_new: this.product.is_new ? this.product.is_new = 1 : this.product.is_new = 0,
                    is_product_of_week: this.product.is_product_of_week ? this.product.is_product_of_week = 1 : this.product.is_product_of_week = 0,
                    opengraph_header_ru:  this.product.opengraph_header_ru,
                    opengraph_header_ua:  this.product.opengraph_header_ua,
                    opengraph_description_ru:  this.product.opengraph_description_ru,
                    opengraph_description_ua:  this.product.opengraph_description_ua,
                    price,
                    price_new,
                    purchase_price,
                    main_category: this.product.main_category,
                    unit: this.product.unit,
                });
            },
            error: (err) => {
                this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
            },
        });
    }

    // отправка формы
    onFormSubmit(form: FormGroup): void {
        this.formWasSubmitted = true;

        // если есть ошибки валидации в полях атрибутов, не даем отправить форму
        const allFieldAttributesArrays = [
            this.fieldNameRuInAttributes,
            this.fieldNameUaInAttributes,
            this.fieldAddedAttributesInAttributes,
        ];
        for (const fieldArray of allFieldAttributesArrays) {
            for (const field of fieldArray) {
                if (!field.isValid) {
                    this.errorService.addError(ErrorTypeEnum.Local, [DEFAULT_MESSAGES.FORM_ERROR]);
                    return;
                }
            }
        }

        // если есть ошибки валидации в полях атрибутов, не даем отправить форму
        const allFieldOptionsArrays = [
            this.fieldNameRuInOptions,
            this.fieldNameUaInOptions,
            this.fieldAddedOptionsInOptions,
            this.fieldPriceInOptions,
            this.fieldUrlInOptions,
        ];
        for (const fieldArray of allFieldOptionsArrays) {
            for (const field of fieldArray) {
                if (!field.isValid) {
                    this.errorService.addError(ErrorTypeEnum.Local, [DEFAULT_MESSAGES.FORM_ERROR]);
                    return;
                }
            }
        }

        // если есть ошибки валидации в полях формы, не даем отправить форму
        if (form.invalid) {
            this.errorService.addError(ErrorTypeEnum.Local, [DEFAULT_MESSAGES.FORM_ERROR]);
            return;
        }

        // получаем значения из формы
        const formValue = form.value;
        formValue.opengraph_image = this.imageOpenGraph;
        formValue.gallery = this.gallery; // поле 'gallery' используется для отправки массива в нужном формате
        formValue.categories = this.selectedCategories;
        formValue.added_attributes = this.addedAttributes;
        formValue.added_options = this.addedOptions;
        formValue.selected_options_viewed_with = this.selectedOptionsViewedWith;

        // отправка формы
        this.apiService
            .put(EndpointTypeEnum.Market, '/products', true, form.value, this.productId)
            .subscribe({
                next: (response: any) => {
                    this.successService.add(ErrorTypeEnum.Local, [DEFAULT_MESSAGES.SUCCESS]);
                    this.addedAttributes = [];
                    this.addedOptions = [];
                    form.controls['mainGalleryImage'].reset();
                    form.controls['secondaryGalleryImages'].reset();
                    form.controls['opengraph_image'].reset();
                    this.generateFeed(this.product._id)
                    this.getProduct(this.productId);
                    this.getAllActiveOptionsByProduct(this.productId);
                    // this.location.back();
                    // this.router.navigate(
                    //     ['/market', this.market._id, 'products'],
                    //     { queryParams: { page: this.storageService.getProductPage() || 1 } }
                    // );
                },
                error: (err: any) => {
                    this.errorService.addError(ErrorTypeEnum.Local, [err.error.message]);
                },
            });
    }

    removeImage(galleryId: string) {
        this.showConfirmation = true;
        this.selectedImageId = galleryId;
    }

    onConfirmationRemoveImage(confirmed: boolean) {
        if (confirmed) {
            this.apiService
                .delete(EndpointTypeEnum.Market, '/products/image', true, this.selectedImageId)
                .subscribe({
                    next: (response: any) => {
                        this.getProduct(this.productId);
                        this.successService.add(ErrorTypeEnum.Local, [DEFAULT_MESSAGES.SUCCESS]);
                    },
                    error: (err) => {
                        this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
                    },
                });
            this.showConfirmation = false;
        } else {
            this.showConfirmation = false;
            this.selectedImageId = null;
        }
    }

    generateFeed(productID: string) {
        this.apiService
            .put(EndpointTypeEnum.Market, `/products/feed/${productID}`, true, { 'market_id': this.market._id, 'categories': this.selectedCategoriesForMerchant})
            .subscribe({
                next: (response: any) => {},
                error: (err) => {
                    this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
                },
            });

        if (this.googleMerchant) {
            this.apiService
                .put(EndpointTypeEnum.Market, '/google-merchant', true, { 'market': this.market._id, 'name': 'Google', 'categories': this.selectedCategoriesForMerchant}, this.googleMerchant._id)
                .subscribe({
                    next: (response: any) => {
                        this.successService.add(ErrorTypeEnum.Local, [DEFAULT_MESSAGES.SUCCESS]);
                    },
                    error: (err: any) => {
                        this.errorService.addError(ErrorTypeEnum.Local, [err.error.message]);
                    },
                });
        } else {
            this.apiService
                .post(EndpointTypeEnum.Market, '/google-merchant', true,  { 'market': this.market._id, 'name': 'Google', 'categories': this.selectedCategoriesForMerchant})
                .subscribe({
                    next: (response: any) => {},
                    error: (err) => {
                        this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
                    },
                });
        }
    }

    getGoogleMerchant(): void {
        this.apiService
            .get(EndpointTypeEnum.Market, '/google-merchant', true, this.market._id)
            .subscribe({
                next: (response: any) => {
                    this.googleMerchant = response.data;
                    this.selectedCategoriesForMerchant = response.data.categories
                },
                error: (err) => {
                    this.errorService.addError(ErrorTypeEnum.Global, [err.error.message]);
                },
            });
    }

    getOptionById(id: string) {
        return this.optionsForDropdown.find((option: any) => option._id === id);
    }

    protected readonly JSON = JSON;
}
