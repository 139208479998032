<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Товари</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Дашборд
            </a>
        </li>
        <li class="breadcrumb-item gray-color">Каталог</li>
        <li class="breadcrumb-item fw-semibold">Товари</li>
    </ol>
</div>

<mat-card class="mb-25 tagus-card">
    <mat-card-header>
        <h5 class="mb-0">
            Список товарів в категорії: {{ currentCategory }}
            <br/><br/>
            <a class="small" mat-stroked-button
               [routerLink]="['/market', market._id, 'products']"
               [queryParams]="{ page: storageService.getProductPage() || 1 }"
               color="warn">
                Повернутися до списку товарiв
            </a>
        </h5>
        <div class="d-flex align-items-center">
            <a mat-flat-button class="default-btn tagus small" [routerLink]="['/market', market._id, 'products', 'create']">
                <i class="ri-add-fill"></i> Додати
            </a>
        </div>
    </mat-card-header>
    <app-local-success></app-local-success>
    <app-modal-confirmation *ngIf="showConfirmation" (confirmed)="onConfirmationRemove($event)"></app-modal-confirmation>
    <mat-card-content>
        <div class="d-flex">
            <mat-form-field appearance="fill" class="width-100">
                <mat-label>Пошук по назвi або артикулу</mat-label>
                <input matInput
                       #searchBlock
                       (keyup)="applyFilter($event)" placeholder="Введите название или артикль">
            </mat-form-field>
            <div style="margin-left: 16px">
                <button mat-icon-button (click)="onClearSearch()">
                    <i class="ri-delete-bin-2-line"></i>
                </button>
            </div>
        </div>

        <div style="display: none">
            <mat-paginator [length]="totalRecords"
                           [pageSize]="pageSize"
                           [hidePageSize]="true"
                           [pageSizeOptions]="pageSizeOptions"
                           showFirstLastButtons>
            </mat-paginator>
        </div>

        <div class="mat-elevation-z8 tagus-mat-table">
            <table mat-table [dataSource]="dataSource">

                <!-- Image Column -->
                <ng-container matColumnDef="image">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element" style="padding: 0 !important;">
                        <div class="d-flex align-items-center product-info">
                            <img
                                *ngIf="element.gallery[0]"
                                [src]="utilityService.addSuffixToImageUrl(backendUrl + '/uploads/' + element.gallery[0].image, '60')"
                                style="width: 50px"
                            >
                        </div>
                    </td>
                </ng-container>

                <!-- Product Name Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef class="text-start">Назва</th>
                    <td mat-cell *matCellDef="let element" class="text-start">
                        <div class="d-flex align-items-center">
                            <a [routerLink]="['/market', this.market._id, 'products', 'edit', element._id]" class="d-flex align-items-center product-info">
                                <span style="width:300px;word-wrap: break-word; overflow-wrap: break-word; white-space: normal; display: block;">
                                    {{element.name_ua}}
                                </span>
                            </a>
                        </div>
                    </td>
                </ng-container>

                <!-- Category Column -->
                <ng-container matColumnDef="category">
                    <th mat-header-cell *matHeaderCellDef>Категорія</th>
                    <td mat-cell *matCellDef="let element">
                        <span style="width:100px;word-wrap: break-word; overflow-wrap: break-word; white-space: normal; display: block;">
                            {{element?.main_category?.name_ua}}
                        </span>
                    </td>
                </ng-container>

                <!-- Brand Column -->
                <ng-container matColumnDef="brand">
                    <th mat-header-cell *matHeaderCellDef>Бренд</th>
                    <td mat-cell *matCellDef="let element">
                        <a [routerLink]="['/market', this.market._id, 'products', 'by-brand', element?.brand?._id]" class="d-flex align-items-center product-info">
                            <span style="width:100px;word-wrap: break-word; overflow-wrap: break-word; white-space: normal; display: block;">
                                {{element?.brand?.name_ua}}
                            </span>
                        </a>
                    </td>
                </ng-container>

                <!-- Article Column -->
                <ng-container matColumnDef="article">
                    <th mat-header-cell *matHeaderCellDef>Артикул</th>
                    <td mat-cell *matCellDef="let element">
                        <span style="width:100px;word-wrap: break-word; overflow-wrap: break-word; white-space: normal; display: block;">
                            {{element.article}}
                        </span>
                    </td>
                </ng-container>

                <!-- Price Column -->
                <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef>Ціна</th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element.options[0].price_new">
                            <s>{{element.options[0].price}}</s>
                            <br/>
                            <span style="color: #b02a37">{{element.options[0].price_new}}</span>
                        </ng-container>

                        <ng-container *ngIf="!element.options[0].price_new">
                            {{element.options[0].price}}
                        </ng-container>
                    </td>
                </ng-container>

                <!-- Stock Column -->
                <ng-container matColumnDef="stock">
                    <th mat-header-cell *matHeaderCellDef>Кількість</th>
                    <td mat-cell *matCellDef="let element">{{element.amount}}</td>
                </ng-container>

                <!-- Availability Column -->
                <ng-container matColumnDef="availability">
                    <th mat-header-cell *matHeaderCellDef>Наявнiсть</th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element.availability">
                            <div style="display: flex;justify-content: center">
                                <div style="
                                    width: 10px;
                                    height: 10px;
                                    border-radius: 50%;
                                    background: #00b69b;
                                "></div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="!element.availability">
                            <div style="display: flex;justify-content: center">
                                <div style="
                                    width: 10px;
                                    height: 10px;
                                    border-radius: 50%;
                                    background: #b02a37;
                                "></div>
                            </div>
                        </ng-container>
                    </td>
                </ng-container>

                <!-- Action Column -->
                <ng-container matColumnDef="action">
                    <th style="display: flex;justify-content: flex-end" mat-header-cell *matHeaderCellDef>Дія</th>
                    <td style="display: flex;justify-content: flex-end" mat-cell *matCellDef="let element">
                        <button type="button" mat-button class="position-relative dot-btn p-0 bg-transparent border-none" [matMenuTriggerFor]="actionMenu">
                            <i class="ri-more-fill"></i>
                        </button>
                        <mat-menu #actionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">
                            <a [routerLink]="['/market', this.market._id, 'products', 'edit', element._id]" mat-menu-item class="bg-transparent gray-color border-none position-relative">
                                <i class="ri-edit-line"></i> Редагувати
                            </a>
                            <button (click)="remove(element._id)" mat-menu-item type="button" class="bg-transparent border-none position-relative">
                                <i class="ri-delete-bin-line"></i> Видалити
                            </button>
                        </mat-menu>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [length]="totalRecords"
                           [pageSize]="pageSize"
                           (page)="onPageChange($event)"
                           [pageSizeOptions]="pageSizeOptions"
                           showFirstLastButtons>
            </mat-paginator>
        </div>
    </mat-card-content>
</mat-card>
