<form class="example-form">
    <mat-form-field appearance="fill" class="width-100">
        <mat-label>Атрибут</mat-label>
        <input type="text" placeholder="Оберіть один" aria-label="Атрибут" matInput [formControl]="inputControl" [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event)" [displayWith]="displayFn">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option._id">
                {{option.name_ua}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</form>