import {Component, ContentChild, Input, TemplateRef} from '@angular/core';

@Component({
    selector: 'app-accordion',
    templateUrl: './accordion.component.html',
    styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent<T> {

    @ContentChild(TemplateRef) templateRef: TemplateRef<{$implicit: T, index: number}>;
    @ContentChild("header") headerRef: TemplateRef<{$implicit: T}>;
    @ContentChild("headerLeft") headerLeftRef: TemplateRef<{$implicit: T, index: number}>;

    @Input() items:T[] = [];

    expandedIndex = 0;

}
