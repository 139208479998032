import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { EndpointTypeEnum } from "../enums/endpoint-type.enum";
import { isPlatformBrowser } from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  backendUrl = environment.backendUrl + '/api/' + environment.versioning;
  httpAuthOptions = {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set(
        'Authorization',
        'Bearer ' + (isPlatformBrowser(this.platformId) ? String(window.localStorage.getItem(environment.user_key)) : ''),
      ),
  };
  httpNoAuthOptions = {
    headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
  };
  httpAuthOptionsWithoutJson = {
    headers: new HttpHeaders()
    // .set('Content-Type', 'application/json')
    .set(
        'Authorization',
        'Bearer ' + (isPlatformBrowser(this.platformId) ? String(window.localStorage.getItem(environment.user_key)) : ''),
    ),
  };

  httpAuthMarkBanner = {
    ...this.httpAuthOptionsWithoutJson,
    headers: this.httpAuthOptionsWithoutJson.headers.set('is-banner', 'true')
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private http: HttpClient,
    ) {}

  get(endpointType: EndpointTypeEnum, endpoint: string, auth: boolean = false, id?: string | null, queryParams?: any): Observable<any> {
    // определяем URL
    let url = this.backendUrl + endpointType + endpoint;
    // добавляем id к URL, если он есть
    if (id) {
      url += '/' + id;
    }

    // определяем httpOptions и queryParams
    const httpOptions = auth ? this.httpAuthOptions : this.httpNoAuthOptions;
    const httpOptionsWithQueryParams = queryParams ? { ...httpOptions, params: queryParams } : httpOptions;

    return this.http.get(url, httpOptionsWithQueryParams);
  }

  post(endpointType: EndpointTypeEnum, endpoint: string, auth: boolean = false, data: any): Observable<any> {
    return this.http.post(
      this.backendUrl + endpointType + endpoint,
      data,
      auth ? this.httpAuthOptions : this.httpNoAuthOptions);
  }

  postMultipartFormData(endpointType: string, endpoint: string, auth: boolean, data: any, isBanner?: boolean): Observable<any> {
    const options = auth ? isBanner ? this.httpAuthMarkBanner : this.httpAuthOptionsWithoutJson : {};
    return this.http.post(
        this.backendUrl + endpointType + endpoint,
        data,
        options
    );
  }

  put(endpointType: EndpointTypeEnum, endpoint: string, auth: boolean = false, data: any, id?: string): Observable<any> {
      const url = this.backendUrl + endpointType + endpoint + (id ? '/' + id : '');
      return this.http.put(
      url,
      data,
      auth ? this.httpAuthOptions : this.httpNoAuthOptions);
  }

  delete(endpointType: EndpointTypeEnum, endpoint: string, auth: boolean = false, id: string): Observable<any> {
    return this.http.delete(
      this.backendUrl + endpointType + endpoint + '/' + id,
      auth ? this.httpAuthOptions : this.httpNoAuthOptions);
  }
}
