<cdk-accordion class="example-accordion">
    <cdk-accordion-item
        *ngFor="let item of items; let index = index;"
        #accordionItem="cdkAccordionItem"
        class="example-accordion-item"
        role="button"
        tabindex="0"
        [attr.id]="'accordion-header-' + index"
        [attr.aria-expanded]="accordionItem.expanded"
        [attr.aria-controls]="'accordion-body-' + index"
    >
        <div class="example-accordion-item-header" (click)="accordionItem.toggle()">
            <span>
            <ng-template [ngTemplateOutlet]="headerRef" [ngTemplateOutletContext]="{$implicit: item}"></ng-template>
            </span>
            <span class="example-accordion-item-description">
            <ng-template [ngTemplateOutlet]="headerLeftRef" [ngTemplateOutletContext]="{$implicit: item,index}"></ng-template>
               <mat-icon *ngIf="accordionItem.expanded">keyboard_arrow_down</mat-icon>
               <mat-icon *ngIf="!accordionItem.expanded" >keyboard_arrow_up</mat-icon>
            </span>
        </div>
        <div
            class="example-accordion-item-body"
            role="region"
            [style.display]="accordionItem.expanded ? '' : 'none'"
            [attr.id]="'accordion-body-' + index"
            [attr.aria-labelledby]="'accordion-header-' + index"
        >
            <ng-template [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{$implicit: item,index}"></ng-template>
        </div>
    </cdk-accordion-item>
</cdk-accordion>
